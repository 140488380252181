.stats-container {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5rem;
    width: 90%;
    box-sizing: border-box;
    margin: auto;
}

.stat-card {
    flex: 1;
    background-color: #f6f8fa;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 1.25rem 1.5rem;
    margin: 0 10px;
    box-shadow: 0px 4px 20px #0000001A;
    text-align: center;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
}

.icon img {
    width: 3rem;
    height: 3rem;
    object-fit: contain;
}

.value {
    font-size: 1.6rem;
    margin: 10px 0;
    color: #238F7C;
    font-weight: bold;
}

.description {
    line-height: 1.4;
    letter-spacing: 0.5px;
    font-size: 0.8rem;
}

@media (max-width: 1200px) {
    .stat-card {
        padding: 1rem 0.5rem;
    }

    .value {
        font-size: 1.2rem;
    }
}

@media (max-width: 1000px) {
    .stat-card {
        padding: 1rem 0.5rem;
    }

    .value {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .stats-container {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }

    .stat-card {
        padding: 1.25rem 0.5rem;
        margin: 0;
    }

    .value {
        font-size: 1.2rem;
    }
}