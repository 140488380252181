.footer-container {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    border-top: 1px solid #E1E1E1;
    height: 8vh;
    min-height: 70px;
    max-height: 100px;
}

.main-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1500px;
    padding: 0 5%;
    width: 90%;
    margin: auto;
    background-color: #F5F5F5;
}

.leftSection {
    font-size: 1rem;
    color: #4A4A4A;
}

.rightSection {
    display: flex;
    gap: 1rem;
}

.rightSection a {
    text-decoration: none;
    color: #333;
    padding: .75rem;
}

.link {
    text-decoration: none;
    color: #4A4A4A;
    transition: 0.3s;
}

.link:hover {
    color: #007BFF;
}

@media (max-width: 700px) {
    /* hide right side if too narrow */
    .rightSection {
        display: none;
    }
}