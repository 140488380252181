.main-page {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 5%;
}

.privacy-policy-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 5%;
}