html, body {
    width: 100%;
    margin: 0;
    padding: 0;
}

.mainPage {
    display: flex;
    flex: 1;
}

.chartMenu {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 1rem;
    box-shadow: 0px 4px 6px #0000001A;
    padding: 1.5%;
    margin: 0.5rem;
    width: 45%;
    box-sizing: border-box;
    flex: 2;
}

.content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* Adjusts elements to take up equal space */
    margin: 2% 5%;
}

.dropdownContainer {
    display: flex;
    gap: 0.75rem;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.dateNavContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Centralizes the date navigation */
}

.dateNavContainer span {
    padding: 0.3rem 1rem;
    /* Increase horizontal padding for clarity */
    background-color: #EAEAEA;
    border-radius: 4px;
}

@media (max-width: 1200px) {
    .mainPage {
        flex-direction: column;
    }

    .chartMenu {
        width: 100%;
    }
}


@media (max-width: 1000px) {

    .chartMenu {
        flex: 1;
    }
}

@media (max-width: 738px) {
    .mainPage {
        gap: 1.5rem;
    }

    .chartMenu {
        padding: 5%;
        margin: 0;
    }
    
    .filterContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* Adjusts elements to take up equal space */
        margin: 2% 5%;
    }

    .content-container {
        padding: 0;
    }

    .dropdownContainer {
        display: flex;
        gap: 0;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
    }

    .dropdown-container {
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem 0;
    }

    .period-container {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .mainPage {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .filterContainer {
      margin: 1% 2%; 
    }
  }