.login-button {
    background-color: #635dff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
}