.main-page-landing {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.landing-page-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0 5%;
    max-width: 100%;
}

.landing-page-left-content {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: space-around;
    padding: 5% 0;
    max-height: 800px;
}

.landing-page-copy {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.landing-page-copy-text {
    font-weight: 600;
    font-size: 3.25rem;
}

.brand-color-main {
    color: #32C5A4;
    font-family: 'Roboto', sans-serif;
}

.brand-color-dark {
    color: rgb(51, 51, 51);
    font-family: 'Roboto', sans-serif;
}

.call-to-action-container {
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 3rem;
    width: 100%;
    max-width: 30vw;
}

.call-to-action-text {
    display: flex;
    width: 100%;
    font-size: 1.25rem;
    font-family: 'Roboto', sans-serif;
}

.call-to-action-btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.call-to-action-btn {
    background-color: #635dff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
}

.landing-page-right-content {
    display: flex;
    flex: 3;
    justify-content: flex-end;
    min-height: calc(3 * 16rem); /* at least as tall as .image-tiles-container */
}


.image-tiles-container {
    position: relative;
    height: calc(3 * 16rem); /* 3 tiles and space for top positioning of #tile-3 */
}


.image-tile {
    width: 16rem;
    height: 16rem;
    border-radius: 20px;
}

#tile-1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

#tile-2 {
    position: absolute;
    top: 200px;
    right: 160px;
    z-index: 3;
}

#tile-3 {
    position: absolute;
    top: 420px;
    right: 20px;
    z-index: 1;
}

/* a muted color for the button next to a #635dff colored button */
.learn-more-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    background-color: #635dff;
    color: white;
}

@media (max-width: 1080px) {
    /* hide the right element when the screen gets too small */
    .landing-page-right-content {
        display: none !important;
    }

    .landing-page-content {
        flex-direction: column;
    }

    .landing-page-left-content {
        padding: 5% 0;
    }

    .landing-page-copy {
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .landing-page-copy-text {
        font-size: 3.5rem;
    }

    .call-to-action-container {
        max-width: 100%;
    }

    .call-to-action-container {
        max-width: 100%;
    }
    
    .call-to-action-btn {
        width: 100%;
    }

    .call-to-action-btn-container {
        justify-content: center;
    }
}   

@media (max-width: 768px) {
    .landing-page-left-content {
        gap: 1rem;
    }
}