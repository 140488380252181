.usage-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
}

.cartesian-grid-stroke {
    stroke: #eee;
}


@media (max-width: 1000px) {
    .usage-chart-container {
        height: 400px; 
    }

}
