.tile {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0px 4px 6px #0000001A;
    background-color: #ffffff;
    overflow: hidden;
}

.tile-link {
    text-decoration: none;
    color: #fff;
    width: 100%;
    box-shadow: 0px 4px 6px #0000001A;
}

.tile-link .tile {
    width: 100%;
    box-shadow: none !important;
}


.pricing-tile-header {
    display: flex;
    justify-content: space-between;
    padding: 2% 4%;
    border-bottom: 1px solid #e0e0e0;
    background-color: #238F7C;
    font-weight: 600;
    color: #ffffff;
}

.tile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.2rem;
    color: #7a7a7a;
}

.time {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
}

.days {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.day {
    color: #9E9E9E;
    font-weight: 400;
}

.day.active {
    color: #32C5A4;
    font-weight: 600;
}

@media (max-width: 1200px) {
    .days {
        max-width: 600px;
    }
}