.layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.layout-content {
    flex: 1;
    margin: 3% auto;
    width: 90%;
    max-width: 1300px;
    display: flex;             /* Add this */
    flex-direction: column;    /* Add this */
}

header, footer {
    padding: 20px;
    background-color: #f5f5f5;
}

@media (max-width: 600px) {
    .layout-content {
      max-width: 90%;
    }
  }