.form-container {
    width: 80%;
    background-color: #f5f5f5;
    border-radius: 12px;
    margin: auto;
    padding: 5%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', 'SF Pro Icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    max-width: 600px;
    border: 1px solid #e6e6e6;  
}

.form-header {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
    color: #2c2c2c;
} 

.form-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin: 1.2rem 0;
    width: 100%;
}

.input-group input {
    background-color: #fff;
}

.dow-label {
    font-size: calc(1rem * 0.75);
    color: rgba(0, 0, 0, 0.6);
    margin-left: 14px;
}

.dow-container {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    width: 100%;
    gap: 10px;  /* added to give space between the toggles */
    height: 56px;
}

.dow-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;  /* makes it round */
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 600;   /* added for better readability */
    border: 1px solid #e6e6e6;  /* added for better readability */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  /* added for better readability */
    color: rgba(0, 0, 0, 0.54);
}

.dow-toggle:hover {
    border: 1px solid #238F7C;
    background-color: #238F7C;
    color: #fff;
}

.dow-toggle.active {
    background-color: #32C5A4;
    color: white;
}

.dow-toggle.active:hover {
    background-color: #238F7C;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.submit-button {
    margin-top: 20px;
    flex: 2;
    background-color: #32C5A4;
    color: #fff;
    border-radius: 8px;
    padding: 12px 20px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
    text-align: center;
}

.delete-button {
    margin-top: 20px;
    flex: 2;
    background-color: #F44336;
    color: #fff;
    border-radius: 8px;
    padding: 12px 20px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
    text-align: center;
}

.delete-button a {
    text-decoration: none;
    color: #32C5A4;
}

.submit-button:hover, .delete-button:hover {
    background-color: #238F7C;
}

.delete-button:hover a {
    color: #fff;
}

.submit-button:hover {
    background-color: #238F7C;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #32C5A4 !important;
}

.MuiInputLabel-outlined.Mui-focused {
    color: #32C5A4 !important;
}

@media (max-width: 768px) {
    .form-container {
        width: 90%;  
        box-sizing: border-box;  
        margin: 5% auto; 
        padding: 5%; 
        min-width: 0;  
    }

    .form-header {
        font-size: 1rem;
    }

    .form-body {
        width: 100%;  
        min-width: 0;       /* Takes up full width of its parent */
    }
}



/* Modal Styles */

.modal-overlay {
    position: fixed;  /* Cover the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent background */
    display: flex;  /* Center the modal content */
    justify-content: center;
    align-items: center;
    z-index: 1000;  /* Ensure it's on top */
}

.modal-content {
    width: 80%;
    max-width: 500px;  /* Ensure the modal isn't too wide on large screens */
    background-color: #fff;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  /* Same shadow as your form for consistency */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.modal-content button {
    margin: 0.5rem;  /* Give the buttons some spacing */
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-content button:hover {
    filter: brightness(0.9);  /* Slightly darken the button on hover */
}