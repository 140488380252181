.header-container {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    border-bottom: 1px solid #E1E1E1;
    height: 8vh;
    min-height: 70px;
    max-height: 100px;
}

.main-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1500px;
    padding: 0 5%;
    width: 90%;
    margin: auto;
    background-color: #F5F5F5;
}

.main-header-left {
    display: flex;
    align-items: center;
}

.header-logo-img {
    height: auto;
    width: 12.5rem;
    max-width: 100%;
}

.main-header-right {
    display: flex;
    gap: 1.25rem;
    align-items: center;
}

.main-header-right a {
    text-decoration: none;
    color: #333; /* Adjust color as needed */
    padding: .5rem;
    transition: background-color 0.2s, color 0.2s;
}

.main-header-right a:hover {
    background-color: #f4f4f4; /* Adjust color as needed */
    color: #555; /* Adjust color as needed */
    border-radius: 5px; /* Optional: rounded edges on hover */
}

.mobile-menu {
    display: none;
}

@media (max-width: 768px) {
    .main-header {
        padding: 0 2%;
    }

    .header-logo-img {
        height: 50%; /* Adjust as needed */
    }

    .main-header-right {
        gap: 10px;
    }

    .desktop-menu {
        display: none;
    }   

    .mobile-menu {
        display: block;
    }
}