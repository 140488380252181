.about-us-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: auto;
    max-width: 1400px;
}

.about-us-left {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%; 
}

.brand-color {
    color: #32C5A4;
}

.about-us-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-us-img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 5%;
    margin-top: calc(100% / 3);
    margin-bottom: auto;
}

@media (max-width : 700px) {
    .about-us-container {
        flex-direction: column-reverse;
    }

    .about-us-left {
        padding: 0;
    }

    .about-us-img {
        margin-top: 0;
        margin-bottom: 0;
        max-height: 340px;
    }
}